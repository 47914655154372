<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <time-task-modal
      v-if="showTaskOptionModal"
      :works="works"
      @close="closeOptionModal"
      @action="addRepeat"
    />
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/config')"
          >
          <span class="mapinfo-title">태스크 실행 시간설정</span>
        </v-row>
        <v-row
          class="map-area"
          justify="center"
        >
          <v-col>
            <table v-if="list.length">
              <tr>
                <th>플랜명</th>
                <th>우선순위</th>
                <th>실행횟수</th>
                <th>실행시간</th>
                <th>삭제</th>
              </tr>
              <tr
                v-for="(item, i) in list"
                :key="i"
                class="btn-container mt-5"
              >
                <td>
                  <span>{{ item.Works[0].work_name }}</span>
                </td>
                <td>
                  <span>{{ item.repeat_order }}</span>
                </td>
                <td>
                  <span>{{ item.repeat_count }}</span>
                </td>
                <td>
                  <span>{{ zero(item.repeat_hour)+ " : "+ zero(item.repeat_min)}}</span>
                </td>
                <td
                  class="btn-col pa-0"
                  type="button"
                  @click="onClickRemoveRepeat(item.repeat_id)"
                >
                  <img src="@/assets/remove.png" alt="삭제">
                </td>
              </tr>
            </table>
            <ul class="mt-3">
              <li class="btn-container mt-5">
                <v-row class="btn">
                  <v-col
                    class="btn-col pa-0"
                    cols="10"
                    type="button"
                    @click="showTaskOptionModal= true"
                  >
                    <span>추가하기</span>
                  </v-col>
                </v-row>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import TimeTaskModal from "@/components/modals/timeTaskModal.vue";
import DefaultHeader from '@/components/header/defaultHeader.vue';
import EventBus from "@/main";
export default {
  name: 'MapInfo',
  components: {
    DefaultModal,
    TimeTaskModal,
    DefaultHeader,
  },
  data () {
    return {
      showModal: false,
      showTaskOptionModal: false,
      modalState: {
        message: null,
        action: null
      },
      list: [],
      repeat_id: null,
      works: [],
      modalConfigSelected: null,
      modalConfigNumber: null,
      modalConfigWork: null,
      modalConfigHour: null,
      modalConfigMin: null,
    };
  },
  created () {
    EventBus.$off('taskConfig');
    EventBus.$on('taskConfig', (configs) => {
      this.modalConfigSelected = configs.selected;
      this.modalConfigNumber = configs.inputNumber;
      this.modalConfigWork = configs.workSelected;
      this.modalConfigHour = configs.hour;
      this.modalConfigMin = configs.min;
    });

    this.initialize();
  },
  methods: {
    initialize() {
      return this.getRepeatList()
        .then(this.fetchWorks)
        .catch(err => { throw Error(err); });
    },
    fetchWorks() {
      return this.$axios.get('/work/allList')
        .then(res => {
          if (res.status === 200) {
            this.works = res.data;
          }
        });
    },
    getRepeatList() {
      return this.$axios.get('/repeat')
        .then(res => {
          if (res.status === 200) this.list = res.data;
        });
    },
    onClickRemoveRepeat(id) {
      this.repeat_id = id;
      this.modalState.message = '시간 지정 태스크를 삭제하시겠습니까?';
      this.modalState.action = '삭제';
      this.showModal = true;
    },
    doAction() {
      if (this.modalState.action === '삭제') {
        this.modalState.message = '시간 지정 태스크를 삭제중입니다';
        this.modalState.action = 'no-action';
        this.removeRepeat();
      }
    },
    removeRepeat() {
      return this.$axios.delete(`/repeat/${this.repeat_id}`, {
        repeatid: this.repeat_id
      })
        .then(res => {
          if (res.status=== 200) this.initialize();
          this.closeModal();
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
    addRepeat() {
      if (this.modalConfigSelected === null||
        this.modalConfigNumber === null||
        this.modalConfigWork === null||
        this.modalConfigHour === null||
        this.modalConfigMin === null
      ) {
        this.$toast('빈칸이 있는지 확인해 주세요', {
          position: 'top-center'
        });
        return;
      }

      const selected = this.modalConfigSelected;
      const number = this.modalConfigNumber;
      const work = this.modalConfigWork;
      const hour = this.modalConfigHour;
      const min = this.modalConfigMin;

      if (number<= 0) {
        this.$toast('태스크 횟수는 0보다 큰 수를 입력해 주세요', {
          position: 'top-center'
        });
        return;
      }


      if (isNaN(parseInt(hour)) || hour>= 24 || hour< 0) {
        this.$toast('시간은 0 ~ 23 만 입력 가능합니다.', {
          position: 'top-center'
        });
        return;
      }


      if (isNaN(parseInt(min)) || min>= 59 || min< 0) {
        this.$toast('분은 0 ~ 59 만 입력 가능합니다.', {
          position: 'top-center'
        });
        return;
      }

      this.closeOptionModal();

      this.works.forEach(i => {
        if (i.work_name === work) {
          return this.$axios.post('/repeat', {
            map_id: i.map_id,
            work_id: i.work_id,
            repeat_order: selected,
            repeat_count: parseInt(number),
            repeat_hour: hour,
            repeat_min: min,
          })
            .then(this.getRepeatList)
            .catch(err => { throw Error(err); });
        }
      });
    },
    zero(num) {
      return num < 10 && num >= 0 ? "0" + num : num;
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    },
    closeOptionModal() {
      this.showTaskOptionModal = false;
      this.modalConfigSelected = null;
      this.modalConfigNumber = null;
      this.modalConfigWork = null;
      this.modalConfigHour = null;
      this.modalConfigMin = null;
    },
  }
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.mapinfo-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px !important;
}

.map-area {
  height: 70vh;
  overflow: auto;
}

.btn-container {
  list-style: none;
  width: 100%;
}

.btn-container > .btn {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  background-color: #00BFB4;
  border-radius: 15px;
  text-align: center;
}

.btn-col {
  align-self: center;
}

.btn-container > .btn  span {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  color: #ffffff;
}

.btn-col > img {
  width: 50px;
  height: 50px;
}

table {
  width: 100%;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  border-collapse: collapse;
}
td, th {
  padding: 50px;
}
tr {border-bottom: 2px solid white}

@media (max-width: 1024px) {
  table {
    width: 100%;
    font-family: sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    border-collapse: collapse;
  }
  td, th {
    padding: 20px;
  }
  tr {border-bottom: 2px solid white}

  .header img {
    width: 30px;
    height: 30px;
  }

  .container > .header > .mapinfo-title {
    font-size: 20px !important;
  }

  .btn-container > .btn{
    width: 100%;
    border-radius: 10px;
  }

  .btn-container > .btn  span {
    height: 50px !important;
    font-size: 20px !important;
  }

  .btn-container > .btn > .col > img {
    width: 15px;
    height: 15px;
  }
}

</style>
